<template>
  <div>
    <select
      v-if="edit"
      v-model="valueLocal"
      v-focus=""
      @blur="
        valueLocal = $event.target.value
        edit = false
        $emit('input', valueLocal)
      "
      @keyup.enter="
        valueLocal = $event.target.value
        edit = false
        $emit('input', valueLocal)
      "
    >
      <option v-for="option in options" :key="option" :value="option">{{
        option
      }}</option>
    </select>
    <span v-else @click="edit = true">
      {{ valueLocal }}
    </span>
  </div>
</template>

<script>
export default {
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      edit: false,
      valueLocal: this.value,
    }
  },

  watch: {
    value: function() {
      this.valueLocal = this.value
    },
  },
}
</script>

<style scoped>
/* input, */
input:focus {
  display: inline-block;
  border: none;
  outline: none;
}
</style>
