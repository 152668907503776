<script>
import NavBar from '@components/nav-bar.vue'
import Header from '@components/header.vue'
import Footer from '@components/footer.vue'

export default {
  components: { NavBar, Header, Footer },
}
</script>

<template>
  <div class="container-fluid">
    <Header />
    <div class="row">
      <NavBar />
      <div class="col-md-10">
        <slot />
      </div>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
