import axios from 'axios'
import Auth from '@aws-amplify/auth'

export const ValidSession = {
  accessToken: {
    jwtToken: 'valid-token-for-valid1',
    payload: {
      sub: 'valid1',
      username: 'admin',
    },
  },
  idToken: {
    jwtToken: 'valid-idtoken-for-valid1',
    payload: {
      email: 'test@test.test',
    },
  },
  refreshToken: {
    token: 'valid-refresh-for-valid1',
  },
}

const MockAuth = {
  signIn: (username, password) => {
    if (username === 'admin' && password === 'password') {
      return Promise.resolve({
        signInUserSession: ValidSession,
      })
    } else {
      return Promise.reject(new Error('cognito mock returned an error'))
    }
  },
  currentSession: () => {
    return Promise.resolve(ValidSession)
  },
}

Auth.configure({
  region: process.env.VUE_APP_COGNITO_REGION,
  userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
  oauth: {
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.VUE_APP_COGNITO_CALLBACK,
    redirectSignOut: process.env.VUE_APP_COGNITO_CALLBACK,
    responseType: 'token',
  },
})

const MyAuth = process.env.VUE_APP_MOCK_COGNITO ? MockAuth : Auth
// Auth.signIn returns:
// {
//   user: CognitoUser;
//   userConfirmed: boolean;
//   userSub: string;
// }
//
// Auth.currentAuthenticatedUser({
//   bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
// }).then(user => console.log(user))
// .catch(err => console.log(err));
//
// Auth.currentSession() returns a CognitoUserSession object which contains JWT accessToken, idToken, and refreshToken.
// This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented. So you can use this method to refresh the session if needed.
// Auth.currentSession()
//     .then(data => console.log(data))
//     .catch(err => console.log(err));

export const state = {
  currentUser: getSavedState('auth.currentUser'),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    if (newValue && 'accessToken' in newValue) {
      newValue = {
        id: newValue.accessToken.payload.sub,
        username: newValue.accessToken.payload.username,
        name: newValue.idToken.payload.email,
        token: newValue.accessToken.jwtToken,
        refresh: newValue.refreshToken.token,
      }
      state.currentUser = newValue
    } else {
      state.currentUser = null
    }
    saveState('auth.currentUser', newValue)
    setDefaultAuthHeaders(state)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    setDefaultAuthHeaders(state)
  },

  // Logs in the current user.
  logIn({ commit, state }, { username, password } = {}) {
    return MyAuth.signIn(username, password).then((user) => {
      commit('SET_CURRENT_USER', user.signInUserSession)
      return state.currentUser
    })
  },

  // Refresh the access token
  refresh({ commit, state }) {
    return MyAuth.currentSession()
      .then((userSession) => {
        commit('SET_CURRENT_USER', userSession)
        return state.currentUser
      })
      .catch(() => this.logOut({ commit: commit }))
  },

  // Logs out the current user.
  logOut({ commit }) {
    commit('SET_CURRENT_USER', null)
  },
}

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.currentUser
    ? `Bearer ${state.currentUser.token}`
    : ''
}
