export default {
  authUrl: process.env.VUE_APP_CB_AUTH_URL,
  jsonapiUrl: process.env.VUE_APP_CB_JSONAPI_URL,
  usersUrl: process.env.VUE_APP_CB_USERS_URL,
  statementsUrl: process.env.VUE_APP_CB_STATEMENTS_URL,
  cognitoClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  cognitoCallback: process.env.VUE_APP_COGNITO_CALLBACK,
  tinkClientId: process.env.VUE_APP_TINK_CLIENT_ID,
  tinkCallback: process.env.VUE_APP_TINK_CALLBACK,
}
