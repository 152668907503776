<script>
import { authComputed } from '@state/helpers'
import NavBarRoutes from './nav-bar-routes.vue'

export default {
  components: { NavBarRoutes },
  data() {
    return {
      persistentNavRoutes: [
        // {
        //   name: 'debug',
        //   title: 'Debug',
        // },
      ],
      loggedInNavRoutes: [
        {
          name: 'home',
          title: 'Home',
        },
        // {
        //   name: 'checkpoints',
        //   title: 'Checkpoints',
        // },
        // {
        //   name: 'accounts',
        //   title: () => 'Bank Accounts',
        // },
        {
          name: 'fetches',
          title: () => (
            <div>
              <b-icon
                icon='file-earmark-arrow-down'
                aria-hidden='true'
              ></b-icon>{' '}
              Bank Fetches
            </div>
          ),
        },
        {
          name: 'bank-accounts',
          title: () => (
            <div>
              <b-icon icon='gear' aria-hidden='true'></b-icon> Bank User
              Accounts
            </div>
          ),
        },
        // {
        //   name: 'fetch-legacy',
        //   title: () => 'Bank Fetches',
        // },
        // {
        //   name: 'tink-link',
        //   title: () => 'Tink Link',
        // },
        {
          name: 'profile',
          title: () => (
            <div>
              <b-icon icon='person' aria-hidden='true'></b-icon> Logged in as{' '}
              {this.currentUser.name}
            </div>
          ),
        },
        {
          name: 'logout',
          title: () => (
            <div>
              <b-icon icon='power' aria-hidden='true'></b-icon> Log out
            </div>
          ),
        },
      ],
      loggedOutNavRoutes: [
        {
          name: 'login',
          title: 'Log in',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>

<template>
  <div class="col-md-2">
    <ul class="nav flex-column nav-pills">
      <NavBarRoutes :routes="persistentNavRoutes" />
      <NavBarRoutes v-if="loggedIn" :routes="loggedInNavRoutes" />
      <NavBarRoutes v-else :routes="loggedOutNavRoutes" />
    </ul>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
