import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from '@router'
import store from '@state/store'
import axios from 'axios'
import App from './app.vue'
import CamBankConfig from './cambank.config'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Globally register all `_base`-prefixed components
import '@components/_globals'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// ici je configure l'api pour le module jsonapi mais c'est pas configuré dans le store
Vue.use({
  install(Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: CamBankConfig.jsonapiUrl,
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    })
  },
})

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
